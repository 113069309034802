<template>
    <div class="c-app flex-row align-items-center login-layout" :class="{ 'c-dark-theme': $store.state.darkMode }">
      <div class="header">
        <CContainer>
          <div class="header-items">
            <img :src="require('@/assets/images/logo.png')" alt="" class="logo">
            <a @click="toLogin" class="sign-up">Login</a>
          </div>
        </CContainer>
      </div>
      <CContainer>
        <CToaster :autohide="3000">
          <template v-for="info in infoList">
            <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
              {{ info.message }}.
            </CToast>
          </template>
        </CToaster>

        <div class="login-card-layout">
          <div class="login-text">
            <h1 class="login-text-heading">Create an Account</h1>
            <p class="login-text-paragraph">
              Create an account now and
              <br>
              explore our amazing features
            </p>
          </div>
          <div class="login-card-body-layout">
            <CCardBody class="login-card-body">
              <CForm class="mb-2 text-center">
                <h1 class="login-card-header">Verify Your Account</h1>
                <p class="verification-info">
                    <!-- Message with verification code has been sent to
                    <span>{{ query.phoneNumber }}</span> -->
                </p>
                <CRow>
                    <div class="verification-code">
                        <p>Please enter the code here</p>
                        <div class="code-input">
                            <input
                                v-for="(n, index) in code"
                                :key="index"
                                type="number"
                                pattern="\d*"
                                :id="'input_' + index"
                                maxlength="1"
                                v-model="code[index]"
                                @input="handleInput"
                                @keypress="isNumber"
                                @keydown.delete="handleDelete"
                                @paste="onPaste"
                            />
                        </div>
                    </div>
                </CRow>
                <div class="verification-code-refresh">Resend in {{countdown}}s</div>
                <CRow>
                  <CCol col="6" class="text-center">
                    <CButton :disabled="buttonDisabled" color="secondary" class="px-4 login-button" @click.prevent="resendCode">Resend Code</CButton>
                  </CCol>
                  <CCol col="6" class="text-center">
                    <CButton color="primary" class="px-4 login-button" @click.prevent="redirectToLogin">Continue</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <p class="login-info">Never share any of your login details with anyone.</p>
          </div>
        </div>
      </CContainer>
    </div>
</template>

  <script>
  // let apiUrl = process.env.VUE_APP_API_URL;
  let apiUrl = process.env.VUE_APP_API_URL;
  let query = JSON.parse(window.localStorage.getItem('query'));

  export default {
    name: "VerifyAccount",
    data: () => {
      return {
        infoList: [],
        logoUrl: "img/logo.png",
        loginObj: {
          email: "",
          password: "",
          rememberMe: ""
          // authenticated: true,
        },
        twilioVerifyOTP: '',
        inputValue: '',
        isEmailInputFocused: false,
        isPasswordInputFocused: false,
        code: Array(6),
        dataFromPaste: undefined,
        keysAllowed: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ],
        userId: '',
        countdown: 120,
        intervalId: null,
        color: "grey",
        buttonDisabled: true,
    };
    },
    computed: {
      inputId() {
        return 'floating-label-input-' + this._uid
      }
    },
    methods: {
        gotToAdmin() {
            var self = this;
            self.$router.push({ path: "/pages/tenantLogin" });
        },

        toLogin() {
          this.$router.push({ path: "login" });
        },

        resendCode() {
          this.startCountdown();
            var url = `${apiUrl}twiliosms`;
            let data = {
              phoneNumber: query.phoneNumber
            }
            return api.call('post', url, data)
              .then((response) => {
                return response
              })
              .catch(data => {
                self.toast(
                  "Error",
                  data.message,
                  "danger"
                );

                console.log(data)
              });
        },

        toast(header, message, color) {
            var self = this;
            self.infoList.push({
            header: header,
            message: message,
            color: color,
            });
        },

        redirectToLogin() {
            let code = this.code.join('');
              var url = `${apiUrl}twiliosms/verify`;
                let data = {
                  phoneNumber: query.phoneNumber,
                  code: code,
                }
                return api.call('post', url, data)
                  .then((response) => {
                    // console.log(response)
                    this.toast(
                      "Info",
                      "Your account has been verified and now can be login.",
                      "success"
                    );
                    setTimeout(() => {
                      if (response.data.isPhoneVerified) {
                        this.$router.push({ path: "login" });
                      }
                    },1000);
                    // return response
                  })
                  .catch(res => {
                    this.toast(
                      "Error Twilio",
                      res.data.message,
                      "danger"
                    );
                  });
              // return api.call('post', url, payload).then((res) => {
              //   console.log('res create user:', res)
              //   // this.userId = res.data.user.id

              //   // this.userPhoneNumber = this.registrationObj.phoneNumber
              //   // this.$router.push({ path: "verify-account"});
              //   // this.twilioVerifyOTP()
              // }).catch(({ data }) => {
              //   this.toast(
              //     "Error",
              //     data.message,
              //     "danger"
              //   );
              // })
            // let userId = this.$route.query.id;
        },

        isNumber(event) {
            event.currentTarget.value = "";
            const keyPressed = event.key;
            if (!this.keysAllowed.includes(keyPressed)) {
                event.preventDefault();
            }
        },

        handleInput(event) {
            const inputType = event.inputType;
            let currentActiveElement = event.target;

            if (inputType === "insertText")
                currentActiveElement.nextElementSibling?.focus();

            if (inputType === "insertFromPaste" && this.dataFromPaste) {
                for (const num of this.dataFromPaste) {
                let id = parseInt(currentActiveElement.id.split("_")[1]);
                currentActiveElement.value = num;
                this.code[id] = num;
                if (currentActiveElement.nextElementSibling) {
                    currentActiveElement = currentActiveElement.nextElementSibling;
                    currentActiveElement.nextElementSibling?.focus();
                }
                }
            }
            console.log(this.code);
        },

        handleDelete(event) {
            let value = event.target.value;
            let currentActiveElement = event.target;
            if (!value)
                currentActiveElement.previousElementSibling?.focus();
        },

        onPaste(event) {
            this.dataFromPaste = event.clipboardData
                ?.getData("text")
                .trim()
                .split("");

            if (this.dataFromPaste) {
                for (const num of this.dataFromPaste) {
                if (!this.keysAllowed.includes(num)) event.preventDefault();
                }
            }
        },

        startCountdown(){
          this.buttonDisabled = true;
          this.countdown = 120;
          this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(this.intervalId);
              this.buttonDisabled = false;
            }
          }, 1000)
        }
    },
    mounted() {
      this.startCountdown();
      setTimeout(() => {
        let query = JSON.parse(window.localStorage.getItem('query'));
        console.log('qqqqq', query)
        console.log(query.phoneNumber);
      }, 10);
    },
    beforeDestroy() {
      clearInterval(this.intervalId); // Clear the interval when the component is destroyed
    }
  };
  </script>

  <style>
  .logo {
    width: 100%;
    background: #fff;
    max-width: 115px;
  }
  .form-group-login {
    position: relative;
    margin-bottom: 24px;
  }

  .floating-label-input {
    border: none;
    border-bottom: 1px solid #D9D9D9;
    padding: 0.5rem;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #262626;
    border-radius: 0;
  }

  .floating-label-input:focus {
    outline: none;
    border-color: #0A1439;
    box-shadow: none;
    border-radius: 0;
  }

  .floating-label {
    position: absolute;
    top: 0;
    left: 8px;
    pointer-events: none;
    font-size: 16px;
    transition: all 0.2s ease;
  }

  .floating {
    transform: translateY(-100%);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #0A1439;
  }

  .login-layout {
    background: #0A1439;
    position: relative;
  }

  .login-layout:after {
    background: #fff;
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 33%;
  }

  .login-text {
    flex-grow: 1;
  }

  .login-card-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 40px;
  }

  .login-card-layout {
    display: flex;
    align-items: center;
  }

  .login-card-body-layout {
    max-width: 475px;
    z-index: 2;
    width: 100%;
  }

  .login-card-body {
    background: #fff;
    width: 100%;
    flex: 0 0 auto;
    border-radius: 8px;
    padding: 80px 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .login-text-heading {
    font-size: 48px;
    font-weight: 700;
    line-height: 55px;
    color: #fff;

  }

  .login-text-paragraph {
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    padding-top: 16px;
  }

  .login-card-util {
    padding-top: 24px;
  }

  .forgot-password {
    font-size: 16px;
    line-height: 19px;
    color: #8C8C8C;
    text-decoration: underline;
    transition: 0.3s ease;
  }

  .remember-me {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #8C8C8C;
  }

  .login-button {
    width: 100%;
    display: block;
    margin-top: 40px;
    padding: 18px;
    max-width: 300px;
    background: #2566EB;
    color: #fff;
    border-radius: 35px;
    font-size: 16px;
    font-weight: 700;
    margin-inline: auto;
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 32px;
    color: #fff;
  }

  .header-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .verification-info {
    color:#0A1439;
    font-size: 16px;
    font-weight: 500;
  }

  .verification-info span {
    color: #8C8C8C;
  }

  .code-input {
    display: flex;
    flex-direction: row;
    gap: 18px;
    }
    input[type="number"] {
        width: 16.666%;
        height: 64px;
        font-size: 50px;
        text-align: center;
        caret-color: transparent !important;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .verification-code {
        padding: 64px 0;
    }

    .verification-code p {
        color:#434343;
        font-size: 14px;
        font-weight: 400;
    }

    .verification-code-refresh {
        color: #8C8C8C;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

  </style>
